import Vue from 'vue'

export default {
  setPageProperties: (state, properties) => {
    state.pageProperties = properties;
  },
  setComponentStatus: (state, { component, status }) => {
    Vue.set(state.componentStatus, component, status);
  },
  setModalOptions: (state, { message = 'An error occured', type = 'error', isActive }) => {
    state.modal.message = message;
    state.modal.type = type;
    state.modal.isActive = isActive;
  },
  setField: (state, { field, value }) => {
    Vue.set(state, field, value)
  },
  setStep: (state, { pageStatus }) => {
    state.pageStatus = pageStatus
  },
  setReceiptToken: (state, receiptToken ) => {
    state.receiptToken = receiptToken
  },
  setReceiptImage: (state , image) => {
    state.receiptImage = image;
  },
  setProductImage: (state , image) => {
    state.productImage = image;
  },
  setBarCode: (state, data) => {
    state.barcode = data;
    state.productCode = data;
  },
  setScannerStatus: (state, status) => {
    state.scannerActive = status
  },
  setProductCode: (state, value) => {
    state.productCode = value
  },
  setSearchStatus: (state, value) => {
    state.searchStatus = value
  },
  setCountryCode: (state, countryCode) => {
    state.countryCode = countryCode;
  },
  setTel: (state, tel) => {
    state.tel = tel;
  },
  deleteItem: (state, item) => {
    state.selectedItems = state.selectedItems.filter(item => {
      return item.id !== item.id
    });
  },
  setProducts: (state, array) => {
    // Add the count property to each product
    let listOfProducts = array.map(el => ({
      ...el,
      count: 1
    }));
    // Add the new products to scannedProducts
    state.scannedProducts = [...state.scannedProducts, ...listOfProducts];
  },
  resetProducts: (state) => {
    state.scannedProducts = [];
  },
  deleteProduct: (state, productId) => {
    state.scannedProducts = state.scannedProducts.filter(pr => {
      return pr.id !== productId
    });
  },
  setQuantityProduct: (state, { product, action, maxProductCount }) => {
    let productIndex = state.scannedProducts.findIndex(pr => {
      return pr.id === product.id
    });
    product = state.scannedProducts[productIndex];
    product.count = action === 'add' ?
      product.count < maxProductCount || !maxProductCount ?
        +product.count + 1 :
        +product.count :
      +product.count - 1;
    product.count = +product.count < 0 ? 0 : product.count;
    product.count = +product.count > 99 ? 99 : product.count;
  },
  setInputQuantityProduct: (state, { productId, count }) => {
    let productIndex = state.scannedProducts.findIndex(pr => {
      return pr.id === productId
    });
    state.scannedProducts[productIndex].count = count === '' ? 1 : +count <= 0 ? 1 : +count > 99 ? 99 : +count;
  },
  setScannerError: (state, error) => {
    state.errorMessage = error
  },
  setImgList: (state, list) => {
    state.imgList = list
  },
  setErrorActivate: (state, data) => {
    state.errorActivate = data
  },
  setReferenceNumber: (state, data) => {
    state.referenceNumber = data
  },
  setCustomerToken: (state, customerToken) => {
    state.customerToken = customerToken;
  },
  setconsumerId: (state, consumerId) => {
    state.consumerId = consumerId;
  },
  setFrontAndBack: (state, frontAndBack) => {
    state.frontAndBack = frontAndBack;
  },
  setJwtToken: (state, jwtToken) => {
    state.jwtToken = jwtToken;
  },
}

<template>
  <div class="smsVerificationWrapper">
    <div class="inner">
      <v-otp-input
        input-classes="otp-input"
        :num-inputs="4"
        separator=""
        :should-auto-focus="true"
        @on-change="handleOnChange"
        @on-complete="handleOnChange"
      />
    </div>
    <div
      class="validation-error"
      v-if="error"
    >
      {{ $t('This field is required') }}
    </div>
    <div
      class="label"
    >
      <a @click="goToNumberSection">{{ $t('Change phone number?') }}</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VOtpInput from "@bachdgvn/vue-otp-input";

export default {
  name: "SmsReceiving",
  components: {
    VOtpInput
  },
  props: {
    endpoint: {
      type: String,
      default: '/mobileVerification'
    },
    successAction: {
      type: Object,
      required: true
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    }
  },
  data () {
    return {
      code: "",
      error: false
    };
  },
  methods: {
    ...mapActions(['verifyCode']),
    async verify () {
      if (!this.code || this.code.length < 4) {
        this.error = true;
        this.scrollTo(`[id=codeForm`);
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
        return
      }

      const formData = {
        mobile: `${this.countryCode}${this.tel}`,
        code: this.code
      };
      this.verifyCode({ formData, endpoint: this.endpoint })
        .then(r => {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          
          this.$store.dispatch("setComponentStatus", {
            component: this.fieldId,
            status: true
          });
        })
        .catch(err => {})
        .finally(() => {
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: false
          });
        });
    },
    handleOnChange(value) {
      this.code = value;
      this.$store.dispatch("setComponentStatus", {
        component: 'code',
        status: value && value.length == 4
      });
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    goToNumberSection () {
      this['goToPage']('sms', this)
    },
  },
  computed: {
    ...mapGetters(['countryCode', 'tel'])
  },
  mounted () {
    this.$eventHub.$on('verifySms-event', this.verify)
  },
  beforeDestroy () {
    this.$eventHub.$off('verifySms-event', this.verify)
  }
};
</script>
<style lang="scss" scoped>
.smsVerificationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.inner {
  border-radius: 2px;
  border: 2px solid #4F4F4F;
  background: #F0F4FD;
  padding: 10px;
}

/deep/ .otp-input {
  width: 100%;
  height: 100%;
  padding: 2px;
  margin: 0 10px;
  font-size: 24px;
  font-weight: 700;
  border: none;
  border-bottom: 2px solid #1B3F9E;
  background: transparent !important;
  text-align: center;
}

.label {
  margin-top: 10px;
  a {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
}

</style>

<template>
  <button
    type="button"
    :class="`btn ${classes}`"
    :style="[styles]"
    :disabled="disabled || loading || dependencies"
    @click="clicked"
  >
    <div v-if="!loading">
      {{ $t(text) }}</div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only"></span>
    </div>
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "button-component",
  data () {
    return {
      loading: false
    };
  },
  props: {
    requires: {
      type: Array
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    action: {
      type: [Object, Array]
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: Object,
      default: () => {}
    },
    startCampaignButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['hasUnresolvedDependencies']),
    dependencies () {
      return this.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    ...mapActions(['checkEligibility']),
    async clicked () {
      if (this.startCampaignButton) {
        const userIsEligible = await this.checkEligibility()
        if (!userIsEligible) return
      }
      if (!this.action) return;
      if (this.action.anchor) {
        window.location.href = this.action.link
      }
      if (this.action.length > 0) {
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/mixins/style/_button.mixin";
@import "@/mixins/style/_color";

.btn {
  @include button();
  &-primary {
    background: #E3010F;
    border-radius: 8px;
    &:focus,
    &:hover {
      background: #FF1F2D;
    }
    &:active {
      background: #B10000;
    }
    &:disabled {
      background: #DBD7DA;
    }
  }
  &-secondary {
    background-color: #FFF;
    border: 1px solid #E3010F;
    color: #E3010F;
    &:focus,
    &:hover {
      background-color: #FFF;
      border: 1px solid #A3010F;
    }
    &:active {
      background-color: #FFF;
      border: 1px solid #A3010F;
    }
    &:disabled {
      background-color: #FFF;
      border: 1px solid #DBD7DA;
    }
  }
  &-tertiary {
    background: #FFF;
    border: 2px solid #A3C6E3;
    color: #00388B;
    &:focus,
    &:hover {
      background: #fff;
      border: 2px solid #00388B !important;
    }
    &:active {
      background: #FFF;
      border: 2px solid #00388B !important;
    }
    &:disabled {
      background: #FFF;
      border: 2px solid #DBD7DA !important;
    }
  }

  &-fourth {
    border: 2px solid #A3C6E3;
    background: #A3C6E3;
    color: #00388B;
    border-radius: 0;
    &:focus,
    &:hover {
      background: #fff;
      border: 2px solid #00388B !important;
    }
    &:active {
      background: #FFF;
      border: 2px solid #00388B !important;
    }
    &:disabled {
      background: #FFF;
      border: 2px solid #DBD7DA !important;
    }
  }
}
</style>

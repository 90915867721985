<template>
  <div
    :class="[{'row': !noRow}, classes, `col-lg-${grid.columns ? grid.columns : ''}`]"
    :style="{ ...styles, ...cssVars }"
  >
    <template v-for="field in fields">
      <componentRenderer
        :class="`col-lg-${field.properties.grid.columns} col-sm-${field.properties.grid.columns}`"
        :field="field"
        :key="field.id"
        @component-updated="updated"
      />
    </template>
  </div>
</template>
<script>
import componentRenderer from "@/components/component-renderer.vue";

export default {
  name: "EmptyBucket",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    },
    errorImages:{
      type: Boolean,
      required: false,
      default: false
    },
    noRow: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: String,
      default: ""
    }
  },
  methods: {
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  /deep/ [disabled] {
    opacity: 0.65;
    pointer-events: none;
  }
}
@media (min-width: 768px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
@media (max-width: 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}

.form-wrapper {
  border-radius: 8px;
  border: 1px solid #010B48;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 40px;
  justify-content: center;
  align-items: center;
}
.btn-bucket {
  justify-content: center;
  column-gap: 20px;
  @media (max-width: 767px) {
    width: 70%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
  }
}
</style>

<template>
  <div class="m-0 p-0">
    <ValidationProvider
      v-if="validation"
      :name="name.toLowerCase()"
      :rules="validation"
      v-slot="{ errors }"
    >
      <div class="form-group">
        <div class="input-group">
          <label
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="input-label"
            :for="id"
          >
            {{ placeholder }}
            <span v-if="validation.includes('required')">*</span>
          </label>
          <v-select
            v-model="localSelected"
            :reduce="option => option.value"
            :options="options"
            label="text"
            value="value"
            :clearable="false"
            :searchable="false"
            :clear-search-on-select="true"
            :placeholder="localSelected ? '' : $t(placeholder)"
            class="w-100 style-chooser"
            :aria-describedby="`${id}-error`"
          >
          </v-select>
        </div>
        <div
          :id="`${id}-error`"
          class="validation-error"
          role="alert"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <template v-else>
      <div class="form-group">
        <div class="input-group">
          <label
            v-if="labelOnTop"
            :style="labelOnTopStyle"
            class="input-label"
          >
            {{ placeholder }}
          </label>
          <v-select
            v-model="localSelected"
            :reduce="option => option.value"
            :options="options"
            label="text"
            value="value"
            :clearable="false"
            :searchable="false"
            :clear-search-on-select="true"
            :placeholder="localSelected ? '' : $t(placeholder)"
            class="w-100 style-chooser"
          >
          </v-select>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  data () {
    return {
      localSelected: this.selectedValue || ""
    };
  },
  components: {
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => {
        return []
      }
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Something"
    },
    width: {
      type: String,
      default: "100%"
    },
    validation: {
      type: String,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    showFlags: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    localSelected (value) {
      this.$emit("component-updated", {
        selectedValue: value
      });
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #000;
  padding-left: 12px;
}

.style-chooser .vs__search {
  height: 0;
  // color: #000;
}

:deep() {
  --vs-selected-bg: #FFF;
  --vs-dropdown-bg: #FFF;
  --vs-dropdown-option-bg: #FFF;
}

</style>

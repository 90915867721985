/* eslint-disable */

<template>
  <div>
    <router-view />
  </div>
</template>
<script>
const version = process.env.VUE_APP_VERSION;

export default {
  created () {
    // Remove console logs when in production
    if (process.env.NODE_ENV !== "development" && this.$route.query.version != "test") {
      console.log = function () { };
    }
    console.log("%cV" + version, "color:red; font-size:32px;");
  },
  mounted () {
    return window.location.href = "https://www.for-me-online.de/"
    // const resizeObserver = new ResizeObserver(entries => {
    //   for (let entry of entries) {
    //     if (entry.contentBoxSize) {
    //       // The standard makes contentBoxSize an array...
    //       if (entry.contentBoxSize[0]) {
    //           setTimeout(() => {
    //             window.top.postMessage(
    //               { request: 'iframeHeight', value: `${entry.target.scrollHeight}` },
    //               '*'
    //             )
    //           }, 1500);
    //       }
    //     }
    //   }
    // });
    // resizeObserver.observe(document.body);
  },
  methods: {
    // TODO: Catch statement added because of node js
    oneTrustModal: function () {
      // eslint-disable-next-line no-undef
      Optanon.OnConsentChanged(() => {
        if (window.segmentaCookieHandler) window.segmentaCookieHandler();
        if (!window.nsId && !window.nsIdPending && window.nsUrl) {
          window.nsIdPending = true;
          // eslint-disable-next-line no-undef
          fetchJsonp(window.nsUrl, {
            jsonpCallbackFunction: "neustar_response"
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              window.nsId = json.nsid;
            })
            .catch(function (er) {
              console.log(er)
            });

          if (window._agknEchoTag) {
            var a = window._agknEchoTag;
            a.setBpId("firstp");
            a.addEchoKeyValue("event", "page_landing");
            a.generateTag();
          }
        }
      });
      // eslint-disable-next-line
      Optanon.ToggleInfoDisplay();
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/styles/_application.scss";

</style>

<template>
  <div class="scan-product-process">
    <div class="overlap">
      <div
        v-for="(step, idx) in steps"
        :key="idx"
        class="overlap-wrapper"
        :class="{ active: step.active, done: step?.done}"
      >
        <div class="circle-wrapper">
          <div class="text-wrapper">
            {{ idx + 1 }}
          </div>
          <img v-if="step?.done" src="@/assets/images/svg/done.svg" alt="Done">
        </div>
        <div class="text-wrapper-2">
          {{ step.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    stepCount: {
      type: Number,
      default: 3
    },
    steps: {
      type: Array,
      default: () => []
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
:root {
  --box-size: 240px;
  --overlap: 5%;

  @media (max-width: 880px) {
    --box-size: 180px;
    --overlap: 45px;
  }
}

.overlap-wrapper:nth-child(1) {
  width: 180px !important;
  left: 80px;
  z-index: 4;

  .circle-wrapper {
    left: 10px !important;
  }

  .text-wrapper {
    left: 9px;
  }

  .text-wrapper-2 {
    left: 50px;
  }
}
.overlap-wrapper:nth-child(2) { left: calc(var(--box-size) - var(--overlap)); z-index: 3; }
.overlap-wrapper:nth-child(3) { left: calc(2 * var(--box-size) - 2 * var(--overlap)); z-index: 2; }
.overlap-wrapper:nth-child(4) { left: calc(3 * var(--box-size) - 3 * var(--overlap)); z-index: 1; }

.scan-product-process {
  height: 40px;
  width: 900px;

  .overlap {
    height: 40px;
    position: relative;
    width: 800px;
  }
  .overlap-wrapper {
    border-radius: 50%;
    position: absolute;
    width: 260px;
    height: 40px;
    background: linear-gradient(180deg, rgb(238, 238, 238) 0%, rgb(204, 215, 232) 100%);
    border-radius: 30px;
    box-shadow: 0px 0px 6px #00000040;
    color: #afafaf;

    .circle-wrapper {
      border: 1px dashed;
      border-color: #aeaeae;
      border-radius: 15px;
      height: 30px;
      left: 65px;
      position: absolute;
      top: 5px;
      width: 30px;

      img {
        display: none;
      }
    }

    &.active {
      border-radius: 30px;
      background: #010B48;
      box-shadow: 0px 0px 8px 0px rgba(0, 56, 139, 0.35);
      color: #FFF !important;
    }
  }
}

.scan-product-process .text-wrapper {
  font-size: 16px;
  font-weight: 700;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 4px;
  white-space: nowrap;
}

.scan-product-process .text-wrapper-2 {
  font-size: 14px;
  font-weight: 700;
  left: 100px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
}

@media (max-width: 880px) {
  .overlap {
    max-width: 100%;
  }
  .scan-product-process {
    width: 300px;
    .overlap-wrapper:nth-child(1) {
      width: 145px !important;
      left: 0;

      .text-wrapper-2 {
        left: 45px;
      }
    }

    .overlap-wrapper:nth-child(2) { left: calc(var(--box-size) - 3 * var(--overlap)); z-index: 3; }
    .overlap-wrapper:nth-child(3) { left: calc(var(--box-size) - 2 * var(--overlap)); z-index: 2; }
    .overlap-wrapper:nth-child(4) { left: calc(var(--box-size) - var(--overlap)); z-index: 1; }

    .overlap-wrapper {
      width: 140px;
    }

    .circle-wrapper {
      left: 105px !important;
    }

    .text-wrapper-2 {
      display: none;
    }
  }

  &.active {
    border-radius: 30px;
    background: #010B48;
    box-shadow: 0px 0px 8px 0px rgba(0, 56, 139, 0.35);
    color: #FFF !important;
    z-index: 10 !important;

    &.overlap-wrapper {
      width: 145px;
    }

    .circle-wrapper {
      left: 9px !important;
    }

    .text-wrapper-2 {
      left: 45px;
      display: block;
      font-size: 12px !important;
      top: 7px;
      max-width: 80px;
      // word-break: break-all;
    }
  }

  &.done {
    z-index: 9 !important;
    
    .circle-wrapper {
      left: 9px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block !important;
      }
    }

    .text-wrapper { 
      display: none;
    }
  }

  
}

</style>



export default {
  isEditMode: true,
  pageProperties: {
    testing: true,
    "pages": [
      { // Home Page
        "pageName": "home",
        isPublic: true,
        pageId: 100,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center"
            },
            "sectionId": "fu30zq",
            "fields": [
              // { // Refresh Token
              //   "fieldType": "refresh-token",
              //   "fieldId": "ij3433"
              // },
              { // Key Visual
                "fieldType": "key-visual",
                "fieldId": "296eg1",
                "properties": {
                  "grid": {
                    "columns": "12"
                  },
                  "noContainer": true,
                  "desktopImageUrl": "assets/images/banner/banner-desktop.png",
                  "tabletImageUrl": "assets/images/banner/banner-desktop.png",
                  "mobileImageUrl": "assets/images/banner/banner-desktop.png",
                  alt: `Ich hab mich glatt verliebt! 1 Monat Parship gratis mit Gillette erleben!* *Erhalte eine 1-monatige Parship Premium-Mitgliedschaft beim Kauf von Gillette Intimate und/oder Gillette Venus & Satin Care Produkten im Wert von mind. 25€. Um die Mitgliedschaft zu erhalten, ist eine Anmeldung bei Parship erforderlich. Es gelten die Parship AGB.`
                }
              },
              {
                "fieldType": "participation",
                "fieldId": "participation",
              },
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  classes: "container",
                  fields: [
                    {
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "40px",
                          marginTop: "40px"
                        },
                        text: "Schritte zur Teilnahme an der Aktion",
                        classes: 'title text-center'
                      }
                    },
                    { // Steps Card
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginTop": "60px",
                          "marginBottom": "60px",
                          "display": "flex",
                          "flex-direction": "row",
                          "justify-content": "center",
                          "columnGap": "40px"
                        },
                        "class": "col-12 home-steps",
                        "fields": [
                          {
                            "fieldType": "step-card",
                            "fieldId": "05m65j",
                            "properties": {
                              "imageSource": "assets/images/steps/step-1.png",
                              "stepTitle": "Produkt kaufen",
                              "grid": {
                                "columns": 4
                              }
                            }
                          },
                          {
                            "fieldType": "step-card",
                            "fieldId": "05m65j",
                            "properties": {
                              "imageSource": "assets/images/steps/step-2.png",
                              "stepTitle": "Kaufbeleg hochladen",
                              "grid": {
                                "columns": 4
                              }
                            }
                          },
                          {
                            "fieldType": "step-card",
                            "fieldId": "05m65j",
                            "properties": {
                              "imageSource": "assets/images/steps/step-3.png",
                              "stepTitle": "Parship Gutschein erhalten",
                              "grid": {
                                "columns": 4
                              }
                            }
                          },
                        ]
                      }
                    },
                    { // Button
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "marginTop": "40px",
                          "marginBottom": "100px",
                          'justify-content': 'center'
                        },
                        "fields": [
                          {
                            "fieldType": "button-component",
                            "fieldId": "x1p9hx",
                            "properties": {
                              "grid": {
                                "columns": "3"
                              },
                              "text": "VORGANG STARTEN",
                              "classes": "btn-primary",
                              "disabled": false,
                              "startCampaignButton": true,
                              "action": [
                                {
                                  "fn": "goToPage",
                                  "params": "barcode"
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Barcode
        "pageName": "barcode",
        pageId: 10011,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "active": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": false
                          },
                          {
                            "label": "Einloggen/ Registrieren",
                            "active": false
                          },
                          {
                            "label": "Gutschein erhalten",
                            "active": false
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Scannen Sie Ihre Produkte",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte geben Sie die Barcodenummer Ihres Produktes ein, machen Sie ein Foto und laden Sie es per Drag and Drop hoch. Bitte achten Sie darauf, dass die Barcode-Informationen der Aktionsprodukte gut erkennbar sind.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Barcode scanner
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Barcode Scanner
                            "fieldType": "barcode-scanner",
                            "fieldId": "barcodeScanner",
                            "properties": {
                              "grid": {
                                "columns": 12
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "receipt-upload"
                              },
                              "endpoint": "/submitProducts",
                              "texts": {
                                receiptUpload: 'Upload',
                                receiptUploadDescription: 'Upload barcode image',
                                receiptUploadSubDescription: 'Drag & Drop',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.',
                                barcodeScan: 'SCAN',
                                stop: 'STOP',
                                barcodeScanDescription: 'Click here and scan the barcode.',
                                barcodeScanSubDescription: '',
                                barcodeAdd: 'ADD',
                                barcodeAddDescription: 'Enter barcode number',
                                notParticipating: 'The barcode does not appear to belong to a P&G product. Do you think this is an error?',
                                notParticipatingVideo: 'Unfortunately, the product does not appear to be participating in the promotion. Please try to enter the barcode in the field above.',
                                timedOut: 'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.',
                                cameraNotFound: 'Please turn on your camera',
                                invalidBarCode: 'The barcode number is invalid. Try it again.'
                              },
                              "cameraTimeOut": 20000,
                              "maxProductCount": 100
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["barcodeScanner"],
                              "action": {
                                "fn": "sendBarcodeProducts",
                                "params": ""
                              }
                            }
                          },
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
         "pageName": "receipt-upload",
         pageId: 10099,
         "sections": [
           { // Steps and Header
             "grid": {
               "columns": 12,
               "alignItems": "center",
               "justifyContent": "center",
               "marginTop": "50px"
             },
             "sectionId": "fu30zq",
             "fields": [
               { // Container
                 "fieldId": "fu0923r",
                 "fieldType": "empty-bucket",
                 "properties": {
                   "grid": {
                     "columns": 12,
                     "alignItems": "center",
                     "justifyContent": "center",
                     "marginBottom": "60px"
                   },
                   "classes": "container",
                   fields: [
                     { // Progress Bar
                        "fieldType": "progress-bar",
                        "fieldId": "progressBar",
                        "properties": {
                          grid: {
                            columns: 12,
                            marginBottom: "50px"
                          },
                          "steps": [
                            {
                              "label": "Produkt kaufen",
                              "active": false,
                              "done": true
                            },
                            {
                              "label": "Kaufbeleg hochladen",
                              "active": true
                            },
                            {
                              "label": "Einloggen/ Registrieren",
                              "active": false
                            },
                            {
                              "label": "Gutschein erhalten",
                              "active": false
                            },
                          ]
                        }
                     },
                     { // Heading
                       "fieldType": "paragraph",
                       "fieldId": "par1001",
                       properties: {
                         grid: {
                           columns: 12,
                           marginBottom: "20px"
                         },
                         text: "Kaufbeleg hochladen",
                         classes: 'heading text-left'
                       }
                     },
                     { // Subheading
                       "fieldType": "paragraph",
                       "fieldId": "par1001",
                       properties: {
                         grid: {
                           columns: 12,
                           marginBottom: "50px"
                         },
                         text: "Bitte laden Sie in diesem Schritt den Kaufbeleg Ihrer Gillette-Produkte hoch",
                         classes: 'subHeading text-left'
                       }
                     },
                     { // Receipt uploader
                       "fieldType": "empty-bucket",
                       "fieldId": "h453eadaf",
                       "properties": {
                         "grid": {
                           "columns": 12,
                           "alignItems": "center",
                           "justifyContent": "space-around",
                           "marginBottom": "60px"
                         },
                         "classes": "receipt-bucket",
                         "fields": [
                           { // Recipt upload
                             "fieldType": "receipt-processor",
                             "fieldId": "klvakj",
                             "properties": {
                               "grid": {
                                 "columns": "6",
                                 "alignItems": "center",
                                 "justifyContent": "center"
                               },
                               "successAction": {
                                 "fn": "goToPage",
                                 "params": "product-upload"
                               },
                               texts: {
                                 choose: 'Upload',
                                 text1: 'Drop your photo here or select file.',
                                 text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                 wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                 fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                               },
                               editTexts: {
                                 crop: "Crop",
                                 erase: "Erase",
                                 drag: "Drag",
                                 zoomIn: "Zoom in",
                                 zoomOut: "Zoom out"
                               },
                               colorSchema: "#3E3D40",
                               "phrases": [
                                 "Please erase your personal data with the eraser tool.",
                                 "Please frame the receipt / invoice in the gray rectangle."
                               ],
                               "cornerDetectionPhrases": [
                                 "Please erase your personal data with the eraser tool.",
                                 "Please frame the receipt / invoice in the gray rectangle."
                               ]
                             }
                           },
                           { // Receipt Info
                             "fieldType": "empty-bucket",
                             "fieldId": "vdf932fd",
                             "properties": {
                               "grid": {
                                 "columns": "6"
                               },
                               "fields": [
                                 {
                                   "fieldType": "receipt-information",
                                   "fieldId": "5mq1p6",
                                   "properties": {
                                     "grid": {
                                       "alignItems": "center",
                                       "justifyContent": "center",
                                       "columns": 12
                                     },
                                     shouldDisplayFull: true,
                                     "texts": {
                                       "descText": `The following information must be visible:`,
                                       "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                       "correct": "Valid",
                                       "notReadabe": "Hard to read",
                                       "folded": "Folded",
                                       "cropped": "Cut off"
                                     },
                                     "hideExamples": false,
                                     "steps": [
                                       { // Retailer
                                         "text": "Retailer / Shop",
                                         "label": "A"
                                       },
                                       {
                                         "text": "Campaign product and its price",
                                         "label": "B"
                                       },
                                       {
                                         "text": "Total sum of the receipt / invoice and applied VAT",
                                         "label": "C"
                                       },
                                       {
                                         "text": "Date and time of purchase",
                                         "label": "D"
                                       },
                                       {
                                         "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                         "label": "E"
                                       }
                                     ]
                                   }
                                 }
                               ]
                             }
                           }
                         ]
                       }
                     },
                     { // Buttons
                       "fieldId": "fu0923r",
                       "fieldType": "empty-bucket",
                       "properties": {
                         "grid": {
                           "columns": "12",
                           "marginBottom": "30px"
                         },
                         classes: "btn-bucket",
                         fields: [
                           { // Continue Button
                             "fieldType": "button-component",
                             "fieldId": "but100",
                             properties: {
                               grid: {
                                 columns: 3
                               },
                               text: "Continue",
                               classes: "btn-primary",
                               "requires": ["klvakj"],
                               "action": {
                                 "fn": "sendReceipt",
                                 "params": ""
                               }
                             }
                           }
                         ]
                       }
                     }
                   ]
                 }
               }
             ]
           }
         ]
      },
      { // Product Upload
        "pageName": "product-upload",
        pageId: 1201,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": true
                          },
                          {
                            "label": "Einloggen/ Registrieren",
                            "active": false
                          },
                          {
                            "label": "Gutschein erhalten",
                            "active": false
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Produktfoto hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie ein Foto der Produktrückseite Ihrer Aktionsprodukte hoch, wobei die EAN Strichcodes gut sichtbar sein müssen.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "prodPhoto",
                            "properties": {
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "login"
                              }
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "product-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "texts": {
                                      desc: "Stellen Sie sicher, dass die folgenden Informationen gut sichtbar sind",
                                      subDesc: ""
                                    },
                                    "image": "assets/images/png/example.png"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "receipt-upload"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["prodPhoto"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhoto"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Login Page
        "pageName": "login",
        pageId: 1202,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Einloggen/ Registrieren",
                            "active": true
                          },
                          {
                            "label": "Gutschein erhalten",
                            "active": false
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Mit for me Account einloggen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Herzlich Willkommen! Fahren Sie entweder als Gast fort; oder fahren Sie hier mit <img src='/assets/images/forme.png' width='50px' alt='ForMe Logo' />, unserem Marketing Programm von Procter & Gamble fort, indem Sie sich registrieren, oder, falls Sie schon ein Konto haben, sich anmelden. Mit einem <img src='/assets/images/forme.png' width='50px' alt='ForMe Logo' /> Konto erfahren Sie direkt von P&G Aktionen, wie z.B. Produkttests, Cash-Back Aktivitäten oder Gewinnspielen von zahlreichen Markenprodukten.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Login
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        classes: "login-page-bucket",
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "loginForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/login",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "sms"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            { // Email
                                              "fieldId": "n6b54v",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "id": "email",
                                                "name": "email",
                                                "label": "E-mail",
                                                "placeholder": "",
                                                "text": "",
                                                "labelOnTop": true,
                                                "validation": "required|email",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            { // Password
                                              "fieldId": "6h54bdd",
                                              "fieldType": "text-input",
                                              "properties": {
                                                "id": "password",
                                                "name": "password",
                                                "label": "Passwort",
                                                "inputType": "password",
                                                "placeholder": "",
                                                "text": "",
                                                "labelOnTop": true,
                                                "validation": "required",
                                                "grid": {
                                                  "columns": 12
                                                }
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": "Ich habe die <a href='/terms' target='_blank'>Teilnahmebedingungen</a> dieser Aktion gelesen und akzeptiere diese."
                                              }
                                            },
                                            { // Login Button
                                              "fieldType": "empty-bucket",
                                              "fieldId": "vb892nwq",
                                              "properties": {
                                                grid: {
                                                  columns: 12,
                                                  'alignItems': 'center',
                                                  "justifyContent": "center"
                                                },
                                                fields: [
                                                  { // Next Button
                                                    "fieldType": "button-component",
                                                    "fieldId": "9o8k7ujyth",
                                                    "properties": {
                                                      "grid": {
                                                        "columns": 6,
                                                        "alignItems": "center",
                                                        "justify-content": "center",
                                                        "marginTop":"20px"
                                                      },
                                                      "classes": "btn-tertiary",
                                                      "text": "Weiter",
                                                      "disabled": false,
                                                      "action": {
                                                        "fn": "sendForm",
                                                        "params": "loginForm"
                                                      }
                                                    }
                                                  },
                                                  { // Forgot Password
                                                    "fieldType": "anchor",
                                                    "fieldId": "fckzg7",
                                                    "properties": {
                                                      "text": "Passwort vergessen?",
                                                      "url": "https://www.for-me-online.de/passwort-vergessen",
                                                      "target": "_blank",
                                                      "grid": {
                                                        "alignItems": "center",
                                                        "justifyContent": "center",
                                                        "columns": "12"
                                                      },
                                                      "cssClass": "forgotPassword",
                                                      "styles": {
                                                        "textAlign": "center",
                                                        "marginTop": "10px",
                                                        "marginBottom": "20px",
                                                        "textDecoration": "underline",
                                                        "color": "#0057B8"
                                                      }
                                                    }
                                                  },
                                                ]
                                              }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "h453eadaf",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "marginBottom": "30px",
                                      "padding": "20px"
                                    },
                                    "classes": "form-wrapper",
                                    "fields": [
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "Sie haben noch kein Konto?",
                                          classes: 'text text-left'
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "Registrieren",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "goToPage",
                                            "params": "register"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                                {
                                  "fieldType": "empty-bucket",
                                  "fieldId": "h453eadaf",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "justifyContent": "center",
                                      "alignItems": "center",
                                      "padding": "20px"
                                    },
                                    "classes": "form-wrapper",
                                    "fields": [
                                      {
                                        "fieldType": "paragraph",
                                        "fieldId": "par1001",
                                        "properties": {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "Als Gast weitermachen und nicht registrieren.",
                                          classes: 'text text-left'
                                        }
                                      },
                                      {
                                        "fieldType": "button-component",
                                        "fieldId": "but100",
                                        properties: {
                                          grid: {
                                            columns: 6
                                          },
                                          text: "Weiter als Gast",
                                          classes: "btn-tertiary",
                                          "action": {
                                            "fn": "goToPage",
                                            "params": "guest-register"
                                          }
                                        }
                                      }
                                    ]
                                  }
                                },
                              ]
                            }
                          },
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Register
        "pageName": "register",
        pageId: 1203,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Einloggen/ Registrieren",
                            "active": true
                          },
                          {
                            "label": "Gutschein erhalten",
                            "active": false
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bei <img src='/assets/images/forme.png' width='100px' alt='ForMe Logo' /> registrieren",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Wir benötigen folgende Informationen, um Ihre Registrierung abschließen zu können,",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "regForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/register",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "sms"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "yh54weg",
                                              "properties": {
                                                "text": "Über Sie",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": "12"
                                                },
                                                "classes": "form-title"
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "b4baqs",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "firstName",
                                                      "id": "firstName",
                                                      "label": "Vorname",
                                                      "placeholder": "Vorname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "gofije",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "lastName",
                                                      "id": "lastName",
                                                      "label": "Nachname",
                                                      "placeholder": "Nachname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "x3d8oz",
                                                    "fieldType": "select-input",
                                                    "properties": {
                                                      "name": "gender",
                                                      "id": "gender",
                                                      "label": "Anrede",
                                                      "placeholder": "Anrede",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      "options": [
                                                        {
                                                          "text": "Herr",
                                                          "value": "male"
                                                        },
                                                        {
                                                          "text": "Frau",
                                                          "value": "female"
                                                        },
                                                        {
                                                          "text": "Divers",
                                                          "value": "other"
                                                        },
                                                        {
                                                          "text": "Keine Angabe",
                                                          "value": "none"
                                                        }
                                                      ],
                                                      "selectedValue": "",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "godate",
                                                    "fieldType": "date-input",
                                                    "properties": {
                                                      "name": "birthdate",
                                                      "id": "birthdate",
                                                      "label": "Geburtsdatum",
                                                      "placeholder": "dd/mm/yyyy",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      "grid": {
                                                        "columns": "6"
                                                      },
                                                      "params": {
                                                        "disabledDates": {
                                                          "from": "18years",
                                                          "to": "01.01.1900"
                                                        }
                                                      }
                                                    }
                                                  },
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "h7kzf9",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "email",
                                                      "id": "email",
                                                      "label": "E-mail",
                                                      "placeholder": "example@example.com",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|email",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  { // Password
                                                    "fieldId": "6h54bdd",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "id": "password",
                                                      "name": "password",
                                                      "label": "Passwort",
                                                      "inputType": "password",
                                                      "placeholder": "Passwort",
                                                      "text": "",
                                                      "labelOnTop": true,
                                                      "validation": "required|password",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "yh54weg",
                                              "properties": {
                                                "text": "Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Großbuchstaben (A-Z), einen Kleinbuchstaben (a-z) und eine Zahl (0-9) enthalten.",
                                                "grid": {
                                                  "columns": "12"
                                                },
                                                "classes": "pass-text"
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      { // Checkboxes
                                        "fieldId": "8k2f023f",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": "12"
                                          },
                                          "fields": [
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j7",
                                              "properties": {
                                                "name": "terms",
                                                "id": "terms",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich bestätige, dass ich über 18 Jahre alt bin, und die <a href="https://termsandconditions.pg.com/de-de/" target="_blank"><u><b>Geschäftsbedingungen</b></u></a>, <a href="http://www.pg.com/privacy/german/privacy_statement.shtml" target="_blank"><u><b>Datenschutzrichtlinien</b></u></a> &amp; <a href="https://justsnap.de/Datenschutz" target="_blank"><u><b>Justsnap Datenschutzrichtlinien</b></u></a> akzeptiere.`
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich habe die <a href="/terms" target="_blank"><b><u>Teilnahmebedingungen</u></b></a> dieser Aktion gelesen und akzeptiere diese.`
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "psyn1c",
                                              "properties": {
                                                "name": "receiveEmails",
                                                "id": "receiveEmails",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": true
                                                  }
                                                },
                                                colorSchema: "#4E4B66",
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                "label": `Bleiben Sie mit uns in Kontakt!
                                                <br><br>
                                                Ich bin damit einverstanden, personalisierte Mitteilungen über Angebote, Neuigkeiten und andere Werbeinitiativen von for me und anderen <a href='https://de.pg.com/marken-und-produkte/' target='_blank'><u><b>P&G-Marken</b></u></a> per E-Mail und über Online-Kanäle zu erhalten. Ich kann die Mitteilungen jederzeit wieder <a href='https://preferencecenter.pg.com/de-de/datarequests/' target='_blank'><u><b>abbestellen</b></u></a>.
                                                <br><br>
                                                Procter & Gamble verarbeitet als Datenverantwortlicher Ihre personenbezogenen Daten, um Ihnen zu ermöglichen, sich auf dieser Website zu registrieren, mit deren Diensten zu interagieren und, sofern Sie zustimmen, Ihnen relevante werbliche Mitteilungen zukommen zu lassen, darunter auch personalisierte Werbebotschaften in den Online-Medien.
                                                <br><br>
                                                <u class='modalOpener' data-text='
                                                  <div>
                                                    <b>*Mehr lesen</b><br>
                                                    Relevante Werbeinformationen und personalisierte Online-Werbebotschaften
                                                    <br><br>
                                                    Unser Ziel ist es, Ihren Kontakt mit uns und den Marken der P&G Familie interessant, angenehm und persönlich zu gestalten. Dabei versuchen wir, unsere werblichen Mitteilungen nach Ihren Vorlieben abzustimmen und vermeiden es, Ihnen Vorschläge zu schicken, die nicht Ihrem Geschmack entsprechen, oder Ihnen Werbebotschaften zukommen zu lassen, die Ihnen bereits mehrfach angezeigt wurden. Wie machen wir das?
                                                    <br><br>
                                                    <ul>
                                                      <li>Wir kombinieren Daten, um unsere Mitteilungen besser auf Ihre Interessen abzustimmen. Wir können auf der Grundlage unseres berechtigten Interesses sowohl Ihre Registrierungsdaten, sowie auch die Daten, die Sie uns bei der Interaktion mit unseren Diensten offenlegen (wie Markenpräferenzen, ausgefüllte Coupons usw.), mit weiteren Attributen, Interessen und demografischen Daten anreichern, die wir aus geschäftlich verfügbaren Quellen oder von anderen Drittparteien erhalten. Ebenso können wir, wenn Sie unsere zielgerichteten Werbe-Cookies oder ähnliche Technologien auf den Websites oder in den Apps von P&G, oder in einigen Fällen auch auf Webseiten oder in Apps von Dritten, akzeptiert haben, die durch unsere zielgerichteten Werbe-Cookies erhobenen Daten auch mit weiteren Informationen ergänzen, die Sie uns bei der Erstellung eines Kontos oder bei der Interaktion mit unseren Diensten zur Verfügung stellen. Wir verarbeiten diese Daten dabei auch mit Hilfe automatisierter Verfahren, um unsere Inhalte und E-Mail-Angebote online (z. B. auf Plattformen Dritter, darunter auch Social Media-Plattformen) wie auch offline (z. B. per Briefpost) zu personalisieren, sofern Sie dem Erhalt geschäftlicher Mitteilungen von uns zugestimmt haben.</li>
                                                      <br>
                                                      <li>Lookalike-Targeting. Auf der Grundlage unseres berechtigten Interesses analysieren wir Ihre Daten auch, um neue Interessenten zu identifizieren, die ein ähnliches Profil wie Sie haben und sich für eine Kontaktaufnahme mit uns interessieren könnten. Wir und die mit P&G verbundenen Unternehmen können hierfür z. B. Ihre verschlüsselte E-Mail-Adresse in Facebook hochladen und Facebook darum bitten, unsere Werbeanzeigen auf der Grundlage von Daten, die über Sie und andere Personen vorliegen, für weitere Personen anzuzeigen, die ähnliche Interessen haben wie Sie, sogar in anderenLändern.</li>
                                                      <br>
                                                      <li>Verwendung Ihrer personenbezogenen Daten, um Sie in den Online-Medien zu adressieren. Wenn Sie eingewilligt haben, werbliche Mitteilungen von uns zu erhalten, werden wir Ihre verschlüsselten personenbezogenen Daten (d. h. Ihre E-Mail-Adresse, Telefonnummer oder Ihre Mobile Advertising-ID) verwenden, um Ihnen Werbebotschaften in den Online-Kanälen (z. B. auf Ihrem Social-Media-Konto) zukommen zu lassen. Wir tun dies mit Hilfe eines Verfahrens, das Ihre Privatsphäre respektiert, indem wir verschlüsselte Daten und sorgfältig ausgewählte Partner verwenden. Insbesondere geben wir Ihre personenbezogenen Daten nur an Dritte weiter, die uns eigens darauf zugeschnittene Werbedienste zur Verfügung stellen, darunter auch die Social-Media-Plattformen. Wir verwenden solche Dienste, um die von uns gespeicherten Hash-Daten mit den personenbezogenen Daten in deren Datenbank abzugleichen, um individuelle Zielgruppen zu erstellen und Werbebotschaften auf Ihre Interessen im Internet zuzuschneiden, darunter auch der Social Media-Plattformen, soweit dies nach geltendem Recht zulässig ist.</li>
                                                    </ul>
                                                    Das hilft uns, Ihnen relevantere Online-Werbebotschaften (Angebote, Informationen über Produkte und Innovationen) zukommen zu lassen, und Ihnen weniger Anzeigenwerbung zu zeigen, da wir erfahren, ob Ihnen eine bestimmte Werbebotschaft bereits zugesendet wurde, und ob wir Sie bei weiteren Werbebotschaften zu demselben Thema ausschließen können. Die meisten unserer Verbraucher wissen es zu schätzen, dass wir ihnen keine Werbeanzeigen für Produkte anzeigen lassen, die sie nicht interessieren oder die wir ihnen zuvor schon oft angezeigt haben, aber wenn Ihnen das nicht wichtig ist, ist das auch kein Problem, da Sie Ihre Präferenzen jederzeit ändern können. Sie können jederzeit Ihre Meinung ändern und die Zustimmung zur Verwendung Ihrer Daten für personalisierte Werbebotschaften und Anzeigenwerbung, darunter auch die Erstellung von benutzerdefinierten Zielgruppen („Custom Audiences“) und Lookalike-Zielgruppen, <a
                                                      href="https://privacypolicy.pg.com/de-DE/"
                                                      target="_blank"
                                                    ><u>hier</u></a> widerrufen. Lesen Sie unsere Datenschutzrichtlinie.
                                                  </div>'
                                                ><b>Mehr lesen*</b></u>.
                                                Weitere Informationen über die Verarbeitung Ihrer Daten und Ihre Datenschutzrechte finden Sie 
                                                <u class='modalOpener' data-text='
                                                  <div>
                                                    <ul>
                                                      <li>
                                                        Unsere <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="https://privacypolicy.pg.com/de-DE/"
                                                        >Datenschutzrichtlinie</a> regelt die Erfassung und Verwendung Ihrer Daten.
                                                      </li>
                                                      <br>
                                                      <li>Das ist der Grund, warum wir Ihre Daten erheben, unsere Rechtsgrundlage für deren Verarbeitung und der Zeitraum, für den Ihre personenbezogenen Daten speichern werden.</li>
                                                      <br>
                                                      <li>
                                                        Unsere E-Mails enthalten Pixel, die P&G helfen zu verstehen, ob Sie mit ihnen interagieren und welche Inhalte bisher für Sie interessant waren. Erfahren Sie mehr dazu <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>hier</u></a>. Sie können sich von unseren Programmen für E-Mail-Mitteilungen jederzeit <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>abmelden</u></a>.
                                                      </li>
                                                      <br>
                                                      <li>
                                                        Ihre personenbezogenen Daten können in einem anderen Land verarbeitet werden, als dem Land, in dem sie erhoben wurden, darunter auch in den USA. Erfahren Sie mehr dazu <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>hier</u></a>.
                                                      </li>
                                                      <br>
                                                      <li>
                                                        Sie können Ihre Betroffenen Rechte ausüben (also auf Ihre personenbezogenen Daten zugreifen, sie berichtigen, löschen, an einen anderen Anbieter übermitteln lassen, der Verarbeitung Ihrer Daten widersprechen oder diese einschränken oder Ihre Einwilligung widerrufen) oder Ihre Präferenzen und Abonnements ändern, indem Sie <a
                                                          href="https://privacypolicy.pg.com/de-DE/"
                                                          target="_blank"
                                                        ><u>hier</u></a> klicken.
                                                      </li>
                                                    </ul>
                                                  </div>'
                                                ><b>hier**</b></u>
                                                oder in unserer vollständigen 
                                                <a href='https://privacypolicy.pg.com/de-DE/' target='_blank'>
                                                <u><b>Datenschutzrichtlinie</b></u>
                                                </a>. Sie sind mindestens 18 Jahre alt, haben die Datenschutzrichtlinien zur Kenntnis genommen und stimmen unseren <a href='https://termsandconditions.pg.com/de-de/' target='_blank'><u><b>Geschäftsbedingungen</b></u></a> zu.`,
                                              }
                                            },
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "login"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "regForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "flexDirection": "column"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "marginBottom": "30px",
                                "padding": "20px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  "properties": {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Als Gast weitermachen und nicht registrieren.",
                                    classes: 'text text-left'
                                  }
                                },
                                {
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Weiter als Gast",
                                    classes: "btn-fourth",
                                    "action": {
                                      "fn": "goToPage",
                                      "params": "guest-register"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "padding": "20px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  "properties": {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Schon registriert?",
                                    classes: 'text text-left'
                                  }
                                },
                                {
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Anmelden",
                                    classes: "btn-fourth",
                                    "action": {
                                      "fn": "goToPage",
                                      "params": "login"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Guest Register
        "pageName": "guest-register",
        pageId: 1204,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Progress Bar
                      "fieldType": "progress-bar",
                      "fieldId": "progressBar",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "steps": [
                          {
                            "label": "Produkt kaufen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Kaufbeleg hochladen",
                            "active": false,
                            "done": true
                          },
                          {
                            "label": "Einloggen/ Registrieren",
                            "active": true
                          },
                          {
                            "label": "Gutschein erhalten",
                            "active": false
                          },
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Teilnahmeformular",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte füllen Sie die Informationsfelder aus, <b>um an der Parship 1 Monats-Premiumaktion teilzunehmen.</b>",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "register-page-bucket",
                              "fields": [
                                {
                                  "fieldType": "form-component",
                                  "fieldId": "guestForm",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px",
                                      "padding": "20px"
                                    },
                                    "endpoint": "/registerAsGuest",
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "sms"
                                    },
                                    "fields": [
                                      {
                                        "fieldId": "fu2309b",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": 12
                                          },
                                          "classes": "form-wrapper",
                                          "fields": [
                                            {
                                              "fieldType": "paragraph",
                                              "fieldId": "yh54weg",
                                              "properties": {
                                                "text": "Über Sie",
                                                "grid": {
                                                  "alignItems": "center",
                                                  "justifyContent": "center",
                                                  "columns": "12"
                                                },
                                                "classes": "form-title"
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "b4baqs",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "firstName",
                                                      "id": "firstName",
                                                      "label": "Vorname",
                                                      "placeholder": "Vorname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "gofije",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "lastName",
                                                      "id": "lastName",
                                                      "label": "Nachname",
                                                      "placeholder": "Nachname",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|namesWithHyphensAndSpaces",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            },
                                            {
                                              "fieldId": "nameWrapper",
                                              "fieldType": "empty-bucket",
                                              "properties": {
                                                "grid": {
                                                  "columns": 12,
                                                  "columnGap": "10px",
                                                  "flexWrap": "nowrap",
                                                },
                                                classes: "inputs-bucket",
                                                "fields": [
                                                  {
                                                    "fieldId": "x3d8oz",
                                                    "fieldType": "select-input",
                                                    "properties": {
                                                      "name": "gender",
                                                      "id": "gender",
                                                      "label": "Anrede",
                                                      "placeholder": "Anrede",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "",
                                                      "options": [
                                                        {
                                                          "text": "Herr",
                                                          "value": "male"
                                                        },
                                                        {
                                                          "text": "Frau",
                                                          "value": "female"
                                                        },
                                                        {
                                                          "text": "Divers",
                                                          "value": "other"
                                                        },
                                                        {
                                                          "text": "Keine Angabe",
                                                          "value": "none"
                                                        }
                                                      ],
                                                      "selectedValue": "",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  },
                                                  {
                                                    "fieldId": "h7kzf9",
                                                    "fieldType": "text-input",
                                                    "properties": {
                                                      "name": "email",
                                                      "id": "email",
                                                      "label": "E-mail",
                                                      "placeholder": "example@example.com",
                                                      "text": "",
                                                      labelOnTop: true,
                                                      "validation": "required|email",
                                                      "grid": {
                                                        "columns": 6
                                                      }
                                                    }
                                                  }
                                                  // {
                                                  //   "fieldId": "godate",
                                                  //   "fieldType": "date-input",
                                                  //   "properties": {
                                                  //     "name": "birthdate",
                                                  //     "id": "birthdate",
                                                  //     "label": "Geburtsdatum",
                                                  //     "placeholder": "dd/mm/yyyy",
                                                  //     labelOnTop: true,
                                                  //     "validation": "",
                                                  //     "grid": {
                                                  //       "columns": "6"
                                                  //     },
                                                  //     "params": {
                                                  //       "disabledDates": {
                                                  //         "from": "18years",
                                                  //         "to": "01.01.1900"
                                                  //       }
                                                  //     }
                                                  //   }
                                                  // },
                                                ]
                                              }
                                            }
                                          ]
                                        }
                                      },
                                      { // Checkboxes
                                        "fieldId": "8k2f023f",
                                        "fieldType": "empty-bucket",
                                        "properties": {
                                          "grid": {
                                            "columns": "12"
                                          },
                                          "fields": [
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j7",
                                              "properties": {
                                                "name": "terms",
                                                "id": "terms",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": `Ich bestätige, dass ich über 18 Jahre alt bin und die <a href='https://www.pg.com/de_DE/terms_conditions/index.shtml' target='_blank'>P&G Geschäftsbedingungen</a>, <a href='https://privacypolicy.pg.com/de/' target='_blank'>P&G Datenschutzrichtlinie</a> & <a href='https://justsnap.de/Datenschutz/' target='_blank'>Justsnap Datenschutzrichtlinien</a> akzeptiere.
                                                <br><br>
                                                Sie können Ihre <u>Datenschutzrechte</u> ausüben oder Ihre Einstellungen und Abonnements im Bereich <a href='https://preferencecenter.pg.com/de-de/' target='_blank'>Meine Anfrage zum Datenschutz</a> ändern.`
                                              }
                                            },
                                            {
                                              "fieldType": "checkbox",
                                              "fieldId": "wgc3j8",
                                              "properties": {
                                                "name": "campaignTermsAccepted",
                                                "id": "campaignTermsAccepted",
                                                "validation": {
                                                  "mandatory": {
                                                    "allowFalse": false
                                                  }
                                                },
                                                "grid": {
                                                  "columns": "12",
                                                  "paddingTop": "10px"
                                                },
                                                colorSchema: "#4E4B66",
                                                "label": "Ich habe die <a href='/terms' target='_blank'>Teilnahmebedingungen</a> dieser Aktion gelesen und akzeptiere diese."
                                              }
                                            },
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "login"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendForm",
                                "params": "guestForm"
                              }
                            }
                          }
                        ]
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "flexDirection": "column"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "marginBottom": "30px",
                                "padding": "20px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  "properties": {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Sie haben noch kein Konto?",
                                    classes: 'text text-left'
                                  }
                                },
                                {
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Registrieren",
                                    classes: "btn-fourth",
                                    "action": {
                                      "fn": "goToPage",
                                      "params": "register"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 12,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "padding": "20px"
                              },
                              "classes": "form-wrapper",
                              "fields": [
                                {
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  "properties": {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Schon registriert?",
                                    classes: 'text text-left'
                                  }
                                },
                                {
                                  "fieldType": "button-component",
                                  "fieldId": "but100",
                                  properties: {
                                    grid: {
                                      columns: 6
                                    },
                                    text: "Anmelden",
                                    classes: "btn-fourth",
                                    "action": {
                                      "fn": "goToPage",
                                      "params": "login"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS
        "pageName": "sms",
        pageId: 1205,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/png/lock.png",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "130px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bitte bestätigen Sie Ihre Identität",
                        classes: 'heading text-center'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: "Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "sms-verification",
                                  "fieldId": "smsVer",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px"
                                    },
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "code"
                                    },
                                    "options": [
                                      {
                                        "name": "Deutschland",
                                        "code": "DE",
                                        "phoneCode": "+49"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "sendSms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // SMS Code
        "pageName": "code",
        pageId: 1206,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/png/lock.png",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "130px"
                        }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Bitte bestätigen Sie Ihre Identität",
                        classes: 'heading text-center'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: "Die Angabe der Mobilrufnummer ist zur Verifizierung Ihrer Teilnahme erforderlich. Die von Ihnen angegebene Mobilrufnummer wird ausschließlich zur Verifizierung verwendet und wird nach der Aktion von uns gelöscht.",
                        classes: 'subHeading text-center'
                      }
                    },
                    { // Register Form
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 10,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "sms-receiving",
                                  "fieldId": "smsVer",
                                  "properties": {
                                    "grid": {
                                      "columns": 6,
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "marginBottom": "50px"
                                    },
                                    "successAction": {
                                      "fn": "goToPage",
                                      "params": "thank-you"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Back Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Zurück",
                              classes: "btn-secondary",
                              "action": {
                                "fn": "goToPage",
                                "params": "sms"
                              }
                            }
                          },
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "action": {
                                "fn": "verifySms",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Thank you Page
        "pageName": "thank-you",
        pageId: 1207,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Thank you image mobile
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        classes: "mobile",
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/thanks.png",
                              "alternateText": "SMS",
                              "classes": "sms-image",
                              "styles": {
                                "max-width": "250px"
                              }
                            }
                          }
                        ]
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Danke für die Teilnahme",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Ihr Kassenbeleg und Ihre Daten wurden erfolgreich an uns übermittelt und werden jetzt überprüft.",
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "padding": "0",
                          "margin": "0"
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 8,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column",
                                "paddingLeft": "0",
                                "marginLeft": "0"
                              },
                              "fields": [
                                { // Clipboard
                                  "fieldType": "clipboard",
                                  "fieldId": "ref123",
                                  "properties": {
                                    "grid": {
                                      "columns": 12,
                                      "marginBottom": "30px",
                                      "paddingLeft": "0",
                                      "marginLeft": "0"
                                    },
                                    "title": "Referenznummer:",
                                    colorSchema: "#010B48",
                                    "variables": {
                                      "referenceNumber": "referenceNumber"
                                    }
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 10,
                                      marginBottom: "50px",
                                      paddingLeft: "0",
                                      marginLeft: "0"
                                    },
                                    text: "Sobald wir Ihre Daten überprüft haben, bekommen Sie entweder eine Bestätigungs-E-Mail oder eine E-Mail mit Rückfragen, falls Ihre Daten unvollständig waren oder Ihr Kaufbeleg nicht erfasst werden konnte. Dies kann bis zu 36 Stunden in Anspruch nehmen. Wir bitten um Ihre Geduld. Nach erfolgreicher Überprüfung des Kaufbelegs und aller weiteren Daten, wenden wir uns per E-Mail erneut an Sie mit Ihrem  persönlichen Parship-Code. Sollten sich Unklarheiten ergeben, werden wir Sie per E-Mail nochmal separat kontaktieren.",
                                    classes: 'text text-left',
                                    styles: {
                                      "font-size": "19px",
                                      "color" : "#4E4B66"
                                    }
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 4,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              classes: "desktop",
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/thanks.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "250px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Gilette website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.gillette.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Terms Page
        "pageName": "terms",
        isPublic: true,
        pageId: 1217,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Embed component
                      "fieldType": "pdf-viewer",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/glatt-verliebt/terms.pdf',
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // FAQ Page
        "pageName": "faq",
        isPublic: true,
        pageId: 1218,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Embed component
                      "fieldType": "pdf-viewer",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        link: 'https://jolt-assets.s3.eu-central-1.amazonaws.com/template/glatt-verliebt/faq.pdf',
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Campaign is Over
        "pageName": "campaign-is-over",
        isPublic: true,
        pageId: 1208,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Der Aktionszeitraum ist vorbei",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: "Registrieren Sie sich, um auf dem aktuellsten Stand zu bleiben. Erhalten Sie Sonderangebote und Updates.",
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/over.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Gilette website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.gillette.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Error
        "pageName": "error",
        isPublic: true,
        pageId: 1209,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "square-image",
                      "fieldId": "image",
                      "properties": {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        "imageSource": "assets/images/png/error.png",
                        "alternateText": "SMS",
                        "classes": "sms-image",
                        "styles": {
                          "max-width": "800px"
                        }
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 8,
                          marginBottom: "50px"
                        },
                        text: "Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut.",
                        classes: 'subHeading text-center'
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Startseite",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Expired
        "pageName": "expired",
        isPublic: true,
        pageId: 1210,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Diese Sitzung ist abgelaufen",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: `Leider ist der Link nicht mehr gültig! Bitte nehmen Sie erneut teil oder kontaktieren Sie unser
                                    <br>
                                    Support Team: <a href='mailto:support@glatt-verliebt.de'>support@glatt-verliebt.de</a>`,
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/over.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Startseite",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "/"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Limit Reached
        "pageName": "limit",
        isPublic: true,
        pageId: 1211,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 7,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "flexDirection": "column"
                              },
                              "fields": [
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "20px"
                                    },
                                    text: "Teilnahmelimit erreicht",
                                    classes: 'heading text-left'
                                  }
                                },
                                { // Subheading
                                  "fieldType": "paragraph",
                                  "fieldId": "par1001",
                                  properties: {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    text: `Die Anzahl der Teilnahmen an dieser Aktion wurde leider schon erreicht.
                                    <br>
                                    <br>
                                    Da noch nicht alle Teilnahmen überprüft sind, und es sein kann, dass einige bereits eingereichten Kaufbelege nicht für die Aktion zugelassen werden, ist es möglich, dass sich die Aktionsseite für einen kurzen Zeitraum wieder öffnet. Bleiben Sie dran - vielleicht klappt es ja doch noch!
                                    <br>
                                    <br>
                                    In jedem Fall würden wir uns freuen, Sie bei unseren nächsten Aktionen dabei zu haben!`,
                                    classes: 'subHeading text-left'
                                  }
                                },
                              ]
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 5,
                                "justifyContent": "center",
                                "alignItems": "center"
                              },
                              "fields": [
                                {
                                  "fieldType": "square-image",
                                  "fieldId": "image",
                                  "properties": {
                                    grid: {
                                      columns: 12,
                                      marginBottom: "50px"
                                    },
                                    "imageSource": "assets/images/png/over.png",
                                    "alternateText": "SMS",
                                    "classes": "sms-image",
                                    "styles": {
                                      "max-width": "400px"
                                    }
                                  }
                                }
                              ]
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Gilette website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.gillette.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Daily Limit Reached
        "pageName": "daily-limit",
        isPublic: true,
        pageId: 1212,
        "sections": [
          {
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "classes": "container",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  fields: [
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Tägliches Limit erreicht!",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: `Die maximale Anzahl an Einlösungen für heute wurden bereits erreicht.Bitte versuchen Sie es morgen erneut.`,
                        classes: 'subHeading text-left'
                      }
                    },
                    {
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "marginBottom": "80px",
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/thanks.png",
                              "alternateText": "SMS",
                              "styles": {
                                "max-width": "300px"
                              }
                            }
                          },
                        ]
                      }
                    },
                    {
                      "fieldType": "button-component",
                      "fieldId": "errorBackButton",
                      "properties": {
                        "text": "Zurück zur Gilette website",
                        "grid": {
                          "columns": 6,
                          "alignItems": "center",
                          "justifyContent": "center",
                          marginBottom: "50px"
                        },
                        "classes": "btn-primary",
                        "disabled": false,
                        "action": {
                          "anchor": true,
                          "link": "https://www.gillette.de/de-de"
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Product ReUpload
        "pageName": "product-reupload",
        isPublic: true,
        pageId: 1214,
        "sections": [
          { // Section
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Produktfoto hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie ein Foto der Produktrückseite Ihrer Aktionsprodukte hoch, wobei die EAN Strichcodes gut sichtbar sein müssen.",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Product Photo uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "justifyContent": "center",
                          "alignItems": "center",
                          "columnGap": "20px",
                          "marginBottom": "50px"
                        },
                        "fields": [
                          { // Product photo upload
                            "fieldType": "product-upload",
                            "fieldId": "prodPhotoRe",
                            "properties": {
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG and PNG files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg and .png files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              "colorSchema": "var(--color2)",
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              reUpload: true,
                              "endpoint": "/uploadProductPhoto",
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              }
                            }
                          },
                          {
                            "fieldType": "empty-bucket",
                            "fieldId": "h453eadaf",
                            "properties": {
                              "grid": {
                                "columns": 6,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "flexDirection": "column"
                              },
                              "fields": [
                                {
                                  "fieldType": "product-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": "12"
                                    },
                                    "texts": {
                                      desc: "Stellen Sie sicher, dass die folgenden Informationen gut sichtbar sind",
                                      subDesc: ""
                                    },
                                    "image": "assets/images/png/example.png"
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          justifyContent: "center",
                          "marginBottom": "30px",
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Weiter",
                              classes: "btn-primary",
                              "requires": ["prodPhotoRe"],
                              "action": {
                                "fn": "sendProductPhotos",
                                "params": "prodPhotoRe"
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // Receipt Upload
        "pageName": "receipt-reupload",
        isPublic: true,
        pageId: 1015,
        "sections": [
          { // Steps and Header
            "grid": {
              "columns": 12,
              "alignItems": "center",
              "justifyContent": "center",
              "marginTop": "50px"
            },
            "sectionId": "fu30zq",
            "fields": [
              { // Container
                "fieldId": "fu0923r",
                "fieldType": "empty-bucket",
                "properties": {
                  "grid": {
                    "columns": 12,
                    "alignItems": "center",
                    "justifyContent": "center",
                    "marginBottom": "60px"
                  },
                  "classes": "container",
                  fields: [
                    { // Query String to State
                      "fieldType": "query-string-to-state",
                      "fieldId": "uwxo2o1",
                      properties: {
                        grid: {
                          columns: 12
                        },
                        variables: [
                          {
                            query: "referenceNumber",
                            state: "referenceNumber"
                          },
                          {
                            query: "token",
                            state: "receiptToken"
                          },
                        ]
                      }
                    },
                    { // 72 Hours check
                      "fieldType": "checkReceiptToken",
                      "fieldID": "dksnfsdkjf",
                      properties: {
                        grid: { columns: 12 }
                      }
                    },
                    { // Heading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "20px"
                        },
                        text: "Kaufbeleg hochladen",
                        classes: 'heading text-left'
                      }
                    },
                    { // Subheading
                      "fieldType": "paragraph",
                      "fieldId": "par1001",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        text: "Bitte laden Sie in diesem Schritt den Kaufbeleg Ihrer Gillette-Produkte hoch",
                        classes: 'subHeading text-left'
                      }
                    },
                    { // Receipt uploader
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 12,
                          "alignItems": "center",
                          "justifyContent": "space-around",
                          "marginBottom": "60px"
                        },
                        "classes": "receipt-bucket",
                        "fields": [
                          { // Recipt upload
                            "fieldType": "receipt-processor",
                            "fieldId": "receiptRe",
                            "properties": {
                              "grid": {
                                "columns": "6",
                                "alignItems": "center",
                                "justifyContent": "center"
                              },
                              "successAction": {
                                "fn": "goToPage",
                                "params": "thank-you"
                              },
                              texts: {
                                choose: 'Upload',
                                text1: 'Drop your photo here or select file.',
                                text2: 'Only JPG, PNG and PDF files are supported Maximum file size: 10MB',
                                wrongFormat: 'This file format is not supported. Only .jpeg, .png and .pdf files are accepted.',
                                fileSizeLimit: 'The file is too large. Only files up to 10MB are supported.'
                              },
                              editTexts: {
                                crop: "Crop",
                                erase: "Erase",
                                drag: "Drag",
                                zoomIn: "Zoom in",
                                zoomOut: "Zoom out"
                              },
                              colorSchema: "#3E3D40",
                              reUpload: true,
                              "phrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ],
                              "cornerDetectionPhrases": [
                                "Please erase your personal data with the eraser tool.",
                                "Please frame the receipt / invoice in the gray rectangle."
                              ]
                            }
                          },
                          { // Receipt Info
                            "fieldType": "empty-bucket",
                            "fieldId": "vdf932fd",
                            "properties": {
                              "grid": {
                                "columns": "6"
                              },
                              "fields": [
                                {
                                  "fieldType": "receipt-information",
                                  "fieldId": "5mq1p6",
                                  "properties": {
                                    "grid": {
                                      "alignItems": "center",
                                      "justifyContent": "center",
                                      "columns": 12
                                    },
                                    shouldDisplayFull: true,
                                    "texts": {
                                      "descText": `The following information must be visible:`,
                                      "descSubText": `Folded or scanned receipts or invoices will not be accepted`,
                                      "correct": "Valid",
                                      "notReadabe": "Hard to read",
                                      "folded": "Folded",
                                      "cropped": "Cut off"
                                    },
                                    "hideExamples": false,
                                    "steps": [
                                      { // Retailer
                                        "text": "Retailer / Shop",
                                        "label": "A"
                                      },
                                      {
                                        "text": "Campaign product and its price",
                                        "label": "B"
                                      },
                                      {
                                        "text": "Total sum of the receipt / invoice and applied VAT",
                                        "label": "C"
                                      },
                                      {
                                        "text": "Date and time of purchase",
                                        "label": "D"
                                      },
                                      {
                                        "text": "Receipt / Invoice ID (not the bank transaction ID)",
                                        "label": "E"
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    { // Buttons
                      "fieldId": "fu0923r",
                      "fieldType": "empty-bucket",
                      "properties": {
                        "grid": {
                          "columns": "12",
                          "marginBottom": "30px"
                        },
                        classes: "btn-bucket",
                        fields: [
                          { // Continue Button
                            "fieldType": "button-component",
                            "fieldId": "but100",
                            properties: {
                              grid: {
                                columns: 3
                              },
                              text: "Continue",
                              classes: "btn-primary",
                              "requires": ["receiptRe"],
                              "action": {
                                "fn": "sendReceipt",
                                "params": ""
                              }
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      { // DOI
        pageName: "doi",
        pageId: 125,
        isPublic: true,
        sections: [
          { // Steps and Header
            grid: {
              columns: 12,
              justifyContent: "center",
              alignItems: "center",
              margin: 0
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // DOI CHECK
                fieldType: "doi",
                fieldId: "doiasdasda",
                properties: {
                  endpoint: '/checkDoi'
                }
              },
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "80px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 6,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        fields: [
                          { // Heading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              text: "Danke für Ihre Anmeldung",
                              classes: 'heading-black text-left'
                            }
                          },
                          { // Subheading
                            fieldType: "paragraph",
                            fieldId: "par1001",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              text: "Sie haben sich erfolgreich für unseren Newsletter angemeldet. Ab sofort erfahren Sie als Eine/Einer der Ersten, sobald des kostenlose Produkttests, Gewinnspiele und inspirierende Artikel gibt.",
                              classes: 'subHeading text-left'
                            }
                          }
                        ]
                      }
                    },
                    { // Thank you image mobile
                      "fieldType": "empty-bucket",
                      "fieldId": "h453eadaf",
                      "properties": {
                        "grid": {
                          "columns": 6,
                          "justifyContent": "center",
                          "alignItems": "center"
                        },
                        "fields": [
                          {
                            "fieldType": "square-image",
                            "fieldId": "image",
                            "properties": {
                              grid: {
                                columns: 12,
                                marginBottom: "50px"
                              },
                              "imageSource": "assets/images/png/thanks.png",
                              "alternateText": "SMS",
                              "classes": "sms-image",
                              "styles": {
                                "max-width": "250px"
                              }
                            }
                          }
                        ]
                      }
                    },
                  ]
                }
              }
            ]
          },
          { // Section
            grid: {
              columns: 12,
              alignItems: "center",
              justifyContent: "center"
            },
            sectionId: "fu30zq",
            classes: "container",
            fields: [
              { // Container
                fieldType: "empty-bucket",
                fieldId: "fu0923r",
                properties: {
                  grid: {
                    columns: 12,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "60px"
                  },
                  fields: [
                    {
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 8,
                          justifyContent: "center",
                          alignItems: "center"
                        },
                        classes: "container",
                        fields: [

                        ]
                      }
                    },
                    { // Key visual
                      fieldType: "key-visual",
                      fieldId: "key100",
                      properties: {
                        grid: {
                          columns: 12,
                          marginBottom: "50px"
                        },
                        styles: {
                          padding: '0 0 0 0',
                          maxWidth: '75vw'
                        },
                        noContainer: true,
                        desktopImageUrl: "assets/images/banner/desktop-banner.jpg",
                        tabletImageUrl: "assets/images/banner/desktop-banner.jpg",
                        mobileImageUrl: "assets/images/banner/mobile-banner.jpg",
                        alt: `Ich hab mich glatt verliebt! 1 Monat Parship gratis mit Gillette erleben!* *Erhalte eine 1-monatige Parship Premium-Mitgliedschaft beim Kauf von Gillette Intimate und/oder Gillette Venus & Satin Care Produkten im Wert von mind. 25€. Um die Mitgliedschaft zu erhalten, ist eine Anmeldung bei Parship erforderlich. Es gelten die Parship AGB.`
                      }
                    },
                    { // Container
                      fieldType: "empty-bucket",
                      fieldId: "fu0923r",
                      properties: {
                        grid: {
                          columns: 12,
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "60px"
                        },
                        classes: "container",
                        fields: [
                          { // Buttons
                            fieldType: "empty-bucket",
                            fieldId: "fu0923r",
                            properties: {
                              grid: {
                                columns: 12,
                                marginBottom: "30px"
                              },
                              classes: "btn-bucket",
                              fields: [
                                { // Continue Button
                                  fieldType: "button-component",
                                  fieldId: "but100",
                                  properties: {
                                    grid: {
                                      columns: 8
                                    },
                                    text: "Zurück zur Gilette website",
                                    classes: "btn-primary",
                                    action: {
                                      fn: "goToLink",
                                      params: "https://www.gillette.de/de-de"
                                    },
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ],
    "header": {
      "fieldType": "navbar",
      "fieldId": "cbx34t",
      "properties": {
        "grid": {
          "columns": 12
        },
        "logo": {
          imageSource: "assets/images/png/logo.png",
          url: '/',
          alt: 'Gillette Logo'
        },
        "favicon": {
          "imageSource": "assets/images/svg/pglogo.svg"
        },
        "fields": [
          {
            "fieldId": "ikz8vc",
            "fieldType": "anchor",
            "properties": {
              "text": "Teilnahmebedingungen",
              "url": "/terms",
              "target": "_blank",
              "cssClass": "nav-link headingLink",
              "styles": {
                'color': '#010B48 !important',
                'text-decoration': 'none !important'
              }
            }
          },
          {
            "fieldId": "slnmdk",
            "fieldType": "anchor",
            "properties": {
              "text": "FAQ",
              "url": "/faq",
              "target": "_blank",
              "cssClass": "nav-link headingLink",
              "styles": {
                'color': '#010B48 !important',
                'text-decoration': 'none !important'
              }
            }
          }
        ],
        "meta": [
          { "name": "title", "content": "Gillette Parship" },
          {
            "name": "description",
            "content": ``
          },
          {
            "name": "keywords",
            "content": `Gillette, Parship, Gillette Parship, Gillette Parship Testwochen,`
          }
        ]
      }
    },
    "footer": {
      "fieldType": "footer-component",
      "fieldId": "4tfbzz",
    },
    errors: {
      de: {
        default: "Hoppla, da ist was schief gelaufen!",
        noEmail: "Es ist kein Konto mit dieser E-Mail-Adresse vorhanden.",
        emailUsed: "Diese E-Mail-Adresse wird bereits verwendet",
        incorrect: "Unzulässiger Benutzername oder falsches Passwort. Bitte versuchen Sie es erneut!",
        invalidCode: "Der Link, den Sie verwenden möchten, ist bereits abgelaufen. Bitte fordern Sie einen neuen Link zum Zurücksetzen des Passworts an.",
        manyTries: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.',
        generateCode: 'Der Bestätigungscode konnte nicht gesendet wenden, weil es sich um eine nicht zugelassene Telefonnummer handelt. Bitte überprüfen Sie die eingegebene Nummer und versuchen Sie es erneut.',
        wrongCode: 'Der SMS-Code ist falsch',
        network: 'Hoppla. Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        '15minsOver': 'Die Sitzung ist abgelaufen. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
        notActive: 'Ungültiger Teilnahmezeitraum. Bitte lesen Sie unsere Teilnahmebedingungen.',
        badRequest: 'Ungültige Anfrage: campaignKey ist erforderlich.',
        alreadyActive: 'Pro IP-Adresse ist nur eine Teilnahme innerhalb von 15 Minuten erlaubt. Bitte probieren Sie es in ein paar Minuten erneut.',
        dailyParticipationLimit: 'Das tägliche Limit wurde leider schon erreicht, aber es ist möglich, dass Plätze wieder frei werden, falls einige Kaufbelege abgelehnt werden. Bleiben Sie dran - vielleicht klappt es ja doch noch! Ansonsten können Sie es gerne morgen erneut versuchen. Der Zähler wird um 8 Uhr zurückgesetzt.'
      }
    },
    "url": "http://justsnap.everydayme.pl/",
    "name": "test-campaign"
  },
  componentStatus: {},
  modal: {
    type: 'error',
    message: 'Ein Fehler ist aufgetreten',
    isActive: false
  },
  campaignId: "gillette-paarship-de",
  campaignKey: "c88ca593-3705-4827-96a9-0a09a47f9321",
  customerToken: '',
  receiptToken: '',
  receiptImage: '',
  referenceNumber: '',
  formData: new FormData(),
  campaignEndDate: '2024-09-30',
  scannerActive: false,
  file: {},
  barcode: {
    code: '',
    format: ''
  },
  productCode: '',
  searchStatus: null,
  imgList: [],
  errorMessage: "",
  errorActivate: false,
  scannedProducts: [],
  frontAndBack: false,
  countryCode: '',
  tel: '',
  consumerId: '',
  remainingCount: 0,
  jwtToken: '',
}

import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import i18n from './i18n'
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';

const ibantools = require('ibantools');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('mandatory', {
  ...rules['required'],
  message: () => i18n.t('validation.mandatory')
})

extend('required', {
  ...rules['required'],
  message: (item) => i18n.t('validation.required', { field: i18n.t(item) })
})

extend('postal', {
  params: ['land'],
  validate (value, { land }) {
    const regex = land == "Germany" ? /^(\d{5})?$/ : /^(\d{4})?$/;
    return (regex.test(value));
  },
  message: () => i18n.t('validation.plz')
});

extend('confirmPass', {
  params: ['pass'],
  validate (value, { pass }) {
    return value === pass
  }
})
extend('hausnr', {
  ...rules['integer']
})
extend('email', {
  ...rules['email'],
  message: () => i18n.t('validation.email')
})
extend('password', {
  validate: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W]{8,}$/.test(value),
  message: () => i18n.t('validation.password')
});
extend('iban', {
  validate: (value, params) => {
    const country = params[0]
    value = country + value
    // const countryCode = value.substring(0, 2)
    return ibantools.isValidIBAN(value.replace(/[^\dA-Z]/g, ''))
  }
});
extend('bic', {
  validate: value => !!ibantools.isValidBIC(value)
});
extend('sepa', {
  validate: (value) => {
    const countryCode = value.substring(2, 0)
    const countToIgnore = ['GB', "LI"]
    return ibantools.isSEPACountry(countryCode) && !countToIgnore.includes(countryCode)
  }
});
extend('namesWithHyphensAndSpaces', {
  validate: value => {
    return /^([a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,}(\s|-))*[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,}$/.test(value);
  },
  message: (item) => i18n.t('validation.namesWithHyphensAndSpaces', { field: i18n.t(item) })
});

extend('ibanName', {
  validate: value => {
    return /^(([a-zA-Z]{1,}(\s|-))*[a-zA-Z]{1,}){3,}$/.test(value);
  }
});

// extend('ibanNamePoland', {
//   validate: value => {
//     return /^(([a-zA-Z]{1,}(\s|-))*[a-zA-Z]{1,}){3,}$/.test(value);
//   }
// });

extend('phoneNumber', {
  ...rules['integer'],
  message: () => i18n.t('validation.phoneNumber')
})
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
    // message: wihtCustomMessages[rule] // assign message
  });
});


localize({
  en: {
    messages: en.messages
  },
  de: {
    messages: de.messages
  }
});

let LOCALE = i18n.locale;
localize(LOCALE);

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get () {
    return LOCALE
  },
  set (val) {
    LOCALE = val
    localize(val);
  }
});

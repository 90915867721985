<template>
  <div class="pl-0" :style="[styles, cssVars]">
    <ValidationProvider :rules="validation" v-slot="{ errors }" :name="name.toLowerCase()">
      <div class="form-group">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{label}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>(optional)</span>
        </label>
        <div class="inputWrapper" role="group">
          <div
            class="radio-input"
            v-for="option in options"
            :key="option.name"
            :class="localSelected == option.value ? 'active' : ''"
          >
          <label class="box-container w-100" :for="option.name">
            <input
              v-model="localSelected"
              type="radio"
              :name="name"
              :id="option.name"
              :value="option.value"
              :disabled="option.disabled"
              autocomplete="off"
              checked
              :aria-describedby="`${id}-error`"
            >
            <span class="checkmark"></span>
            {{option.text}}
          </label>
          </div>
        </div>
        <div :id="`${id}-error`" class="invalid-feedback" v-if="errors.length">
          <!-- <img class="error_min" src="../../assets/icons/form/error.svg" alt="error"> -->
          {{errors[0]}}
        </div>

      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: "radio-input",
  data () {
    return {
      localSelected: this.selectedValue || ""
    };
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    styles:{
      type: Object,
      default: ()=>{}
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    colorSchema: {
      type: String,
      default: ""
    }
  },
  watch: {
    localSelected (value) {
      this.$emit("component-updated", {
        selectedValue: value
      });
      this.$emit("input", value);
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorSchema
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.inputWrapper {
  display: flex;
  justify-content: flex-start;
}
/* The container */
.box-container {
  display: block;
  position: relative;
  padding: 5px 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

/* Hide the browser's default radio button */
.box-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border: 1px solid var(--color);
  border-radius: 50%;
  margin-bottom: 0 !important;
}

// /* On mouse-over, add a grey background color */
.box-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.box-container input:checked ~ .checkmark {
  background-color: #FFF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.box-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.box-container .checkmark:after {
  top: 1px;
  // transform: translateY(-50%);
	left: 1px;
  // transform: translateX(-50%);
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: var(--color);
  color: var(--color);
}

</style>

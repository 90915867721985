<template>
  <div class="footerWrapper">
    <div class="footerBottom">
      <div class="container">
        <div class="col-lg-8 links">
          <div>
            <img src="@/assets/images/png/logo-white.png" alt="Gillette logo" style="max-width: 100%;max-height: 40px">
            <p class="py-4">
              Unsere Supportabteilung steht Ihnen bis zum <b>15.10.2024</b> zur Verfügung: <a href="mailto:support@glatt-verliebt.de"><span>support@glatt-verliebt.de</span></a></p>
          </div>
          <div>
            <ul class="links bottom-links">
              <li>
                <a href="https://www.gillette.de/de-de/kontakt" target="_blank">
                  {{ $t('Kontakt') }}
                </a>
              </li>
              <li>
                <a href="https://termsandconditions.pg.com/de-de/" target="_blank">
                  {{ $t('Geschäftsbedingungen') }}
                </a>
              </li>
              <li>
                <a href="https://preferencecenter.pg.com/de-de/" target="_blank">
                  {{ $t('Meine Daten') }}
                </a>
              </li>
              <li>
                <a href="https://privacypolicy.pg.com/de-de/" target="_blank">
                  {{ $t('Datenschutz') }}
                </a>
              </li>
              <li>
                <a href="https://de.pg.com/impressum/" target="_blank">
                  {{ $t('Impressum') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 powered">
          <p class="d-none">© 2023 Procter & Gamble</p>
          <a href="https://www.justsnap.de/" target="_blank">
            <img src="@/assets/images/png/js-logo.png" alt="jstnp">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-component",
  props: {
    packshot: {
      type: Object,
      default: () => {
        return {
          src: "/assets/images/footer-product.png",
          style: {}
        }
      }
    },
    supportMail: {
      type: Object,
      required: false,
      default: () => {
        return {
          text: 'kontakt@oralb-testen.de',
          date: '06.03.2023',
          show: false,
        }
      }
    }
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

$tablet : 991px;

.footerBottom {
  color: #FFF;
  background-color: #010B48;
  width: 100%;
  padding: 30px 20px;

  .container {
    display: flex;
    justify-content: space-between;
  }
}
.links {
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    color: #fff !important;
    text-decoration: none !important;
  }

  &.bottom-links {
    padding-bottom: 30px;
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
    li {
      margin-right: 30px;
      margin-top: 10px;
    }
  }
}

.powered {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 30px;
  margin-left: 30px;

  img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767px) {
  .footerBottom {
    font-size: 16px;
    padding: 30px 5px;
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
    }
  }
  .links {
    text-align: center;

    p {
      text-align: left;
    }
  }

  .bottom-links {
    li {
      display: inline;
      &:not(:last-child) {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
      }
    }
  }

  .powered {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    img {
      width: 40px;
      height: 40px;
    }

    p {
      display: block !important;
    }
  }
}

</style>

<template>
  <div class="product-info gray_block">
    <div>
      <div class="inner_gray_block">
        <span class="desc-text" v-html="$t(texts.desc)"></span>
      </div>
      <div class="row align-items-center">
        <img class="img-fluid" :src="require(`@/${image}`)" alt="Sample product image" />
      </div>
      <div class="row col-12 col-sm-12 col-md-6">
        <p class="subDesc-text" v-html="$t(texts.subDesc)">
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "product-information",
  props: {
    texts: {
      type: Object,
      default: () => {},
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    },
    color: {
      type: String,
      default: "#808080"
    },
    image: {
      required: false,
      type: String,
      default: "receipt"
    }
  }
};
</script>
<style lang="scss" scoped>

.desc-text {
  text-align: left;
  font-size: 18px;
}

.gray_block {
  padding: 60px 0px 0px 60px;
  border-radius: 4px;
  @media (max-width: 991px) {
    padding: 40px 60px;
  }
  @media (max-width: 767px) {
    padding: 40px 0;
  }
  .inner_gray_block {
    margin-bottom: 50px;
  }
}
.wraper_punkt {
  margin-bottom: 30px;
  li {
    margin-bottom: 30px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 19px;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    .item {
      width: 25px;
      height: 25px;
      display: inline-block;
      background: #8b8b8b;
      color: #ffffff;
      border-radius: 100px;
      text-transform: uppercase;
      margin-right: 20px;
      text-align: center;
      padding: 3px 8px;
    }
    .item_text {
      font-size: 16px;
      // color: #0f2967;
      font-weight: 400;
      display: inline-block;
    }
    .item_label {
      font-weight: 700;
      font-size: 14px;
      line-height: 26px;
      // color: #f2ac29;
      padding-right: 15px;
    }
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.drop_down {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.drop_down_wrapper {
  padding: 10px !important;
  // border-radius: 100px;
  margin-bottom: 20px !important;
}

@media all and (orientation: landscape) {
  .drop_down {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0px;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .drop_down_wrapper {
    padding: 10px !important;
    border-radius: 100px;
    margin-bottom: 20px !important;
  }
  @media screen and (max-width: 425px) {
    .stepCardWrapper {
      padding: 15px !important;
      margin-bottom: 200px !important;
      border-radius: 30px;
      height: 450px;
      background-color: white !important;
    }
    .drop_down {
      display: none;
    }
  }
  @media screen and (max-width: 375px) {
    .stepCardWrapper {
      font-size: 12px !important;
    }
  }
}
</style>
